import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const LegalNoticePage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Legal Notice — {site.siteMetadata.title}</title>
        <meta name="description" content={"Legal notice of " + site.siteMetadata.description} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Legal Notice</h1>
      <h2>Information provided pursuant to § 5 of the TMG (German Telemedia Act):</h2>
      <p className="primary-content">
        Christian Herzog<br/>
        Holunderweg 20<br/>
        88045 Friedrichshafen<br/>
        Germany<br/>
      </p>
      <h2>Contact details:</h2>
      <p className="primary-content">
      Tel: +49 151 2023 7183<br/>
      Email: chherzog32@gmail.com<br/>
      </p>
      <h2>Responsible for the content pursuant to § 55 para. 2 of the RStV (German Interstate Broadcasting Treaty):</h2>
      <p className="primary-content">
        Christian Herzog<br/>
        Holunderweg 20<br/>
        88045 Friedrichshafen<br/>
        Germany<br/>
      </p>
      <h2>Dispute settlement</h2>
      <p className="primary-content">
      The European Commission provides a platform for online dispute resolution: http://ec.europa.eu/consumers/odr
You can find our email address at the top of this Legal Notice. We are neither willing nor obligated to participate in a dispute settlement in front of a consumer arbitration board.
      </p>
    </Layout>
  )
}
export default LegalNoticePage
export const pageQuery = graphql`
  query LegalNoticePageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`